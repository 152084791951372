import React from 'react';
import PropTypes from 'prop-types';
import BlocAgence from './blocAgence';
import bgbtn from '../../images/agence-info/bg-btn.jpg';

import './blocAgences.scss';

const BlocAgences = ({ agences }) => {
  return (
    <div className="bloc-agences">
      <div className="bloc-agences-content">
        {agences.map((agence, i) => <BlocAgence key={"agence-" + agence.Slug || i} agence={agence} />)}
      </div>
      <a href="/mon-agence-indisponible" className="bloc-agences-footer">
        <div style={{backgroundImage: `url("${bgbtn}")`}} className="bloc-agences-footer--content">
          <h2>Le reste de la France</h2>
        </div>
      </a>
    </div>
  );
};

BlocAgences.propTypes = {
  agences: PropTypes.arrayOf(PropTypes.exact({
    Nom: PropTypes.string.isRequired,
    Image: PropTypes.string.isRequired,
    Slug: PropTypes.string
  }))
};

export default BlocAgences;