import React from 'react';
import PropTypes from 'prop-types';

const BlocAgence = ({ agence }) => {
  return (
    <a
      href={agence.Slug ? `/agence/${agence.Slug}` : undefined}
      className="bloc-agence" style={{backgroundImage: `url(${agence.Image.localFile.childrenImageSharp[0].gatsbyImageData.images.fallback.src})`}}
    >
      <div className="bloc-agence--infos">
        <h2>{agence.Nom}</h2>
      </div>
    </a>
  );
};

BlocAgence.propTypes = {
  agence: PropTypes.exact({
    Nom: PropTypes.string.isRequired,
    Image: PropTypes.string.isRequired,
    Slug: PropTypes.string
  }),
};

export default BlocAgence;